.settings-panel {
  background-color: #f0f0f0;
  width: 20rem;
  transition: transform 0.3s ease;
  padding: 2rem 1rem 1rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.settings-panel label {
  font-size: 0.8rem;
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
}

.settings-panel input {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.75rem;
}

.settings-panel button {
  font-size: 1rem;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  padding: 0.4rem 1.25rem;
  background-color: #e0e0e0;
}

.settings-panel button.new {
  margin-top: 1rem;
}