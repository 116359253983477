.clock {
  padding: 2rem 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.bar {
  max-width: 10rem;
  min-width: 5rem;
  margin: 0 1rem;
  flex-grow: 1;
  text-align: center;
  padding: 1rem;
}