.timer-settings {
  padding: 0.5rem 0;
  width: 100%;
}

.timer-settings .overview {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.timer-settings .edit {
  position: relative;
}

.timer-settings .edit label {
  margin-bottom: 0.75rem;
  font-size: 2rem;
}

.timer-settings .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.timer-settings button.done {
  align-self: baseline;
}