.app {
  width: 100%;
  height: 100%;
}

.app .app-body {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
}

button.settings-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;

  font-size: 1.4rem;
  transition: transform 0.3s;
  transform: rotate(90deg);
}

button.settings-button.inactive {
  opacity: 0.3;
  transform: rotate(0deg);
}

button.settings-button.spin {
  animation: roll 0.3s 1;
}
